$count: 200;

.g-fire {
  position: absolute;
  top: -50%;
  left: 50%;
  border-radius: 45%;
  box-sizing: border-box;
  border: 120px solid #001529;
  border-bottom: 120px solid transparent;
  transform: translate(-50%, 0) scaleX(0.45);
  background-color: #761b00;
}

.g-ball {
  position: absolute;
  top: 60px;
  transform: translate(0, 0);
  background: #fa8763;
  border-radius: 50%;
  z-index: -1;
  mix-blend-mode: screen;
}

@for $i from 1 to $count {
  .g-ball:nth-child(#{$i}) {
    $width: #{random(50)}px;

    width: $width;
    height: $width;
    left: calc(#{(random(70))}px - 55px);
  }

  .g-ball:nth-child(#{$i}) {
    animation: movetop 1s linear -#{random(3000) / 1000}s infinite;
  }
}

@keyframes movetop {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(0, 0);
  }
  87.7% {
    transform: translate(0, -170px);
    opacity: 0;
  }
  100% {
    transform: translate(0, -170px);
    opacity: 0;
  }
}

.ant-layout-sider-children {
  background: #ffffff;
  // background: #f5f5f5;
}

.site-logo {
  height: 2vh;
  background: #87ceeb;
  overflow: hidden;
  // filter: blur(1px) contrast(5);
}

.site-layout .site-layout-background {
  padding: 0;
  height: 2vh;
  background-color: #87ceeb;
  // background-image: linear-gradient(
  //   to right,
  //   #eea2a2 0%,
  //   #bbc1bf 19%,
  //   #57c6e1 42%,
  //   #b49fda 79%,
  //   #7ac5d8 100%
  // );
}

.myBackTop {
  right: 50px;

  .ant-back-top-content {
    background: #1890ff;
    opacity: 0.3;
    transition: all 0.3s;
    box-shadow: 0 0 15px 1px rgba(20, 1, 64, 0.1);

    &:hover {
      opacity: 1;
    }
  }
}
